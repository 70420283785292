import { useEffect } from "react";
import { useNavigate, useParams } from "react-router-dom";

const RedirectCategory = () => {   
    const { id } = useParams();
    const navigate = useNavigate();

  useEffect(() => {
    navigate(`/simulation/${id}`);
  }, [id]);

    return null;
}

export default RedirectCategory;