import styled from '@emotion/styled';
import { Link } from 'react-router-dom';
import InstagramIcon from '@mui/icons-material/Instagram';
import FacebookIcon from '@mui/icons-material/Facebook';


export const FooterStyled = styled('footer')(({ theme }) => `
display: flex;
flex-direction: column;
justify-content: center;
align-items: center;
gap:4px;
width: 100%;
height: 80px;
background-color: ${theme.palette.background.primary};
padding: 10px;
font-size: 12px;
line-height: 1.1;
@media (min-width: 600px) {
   padding: 10px 50px;
   font-size: 14px;
}
`);


export const HelpDescription = styled('p')(({ theme }) => `
color: ${theme.palette.text.primary};
`);

export const WrapperMailSocial = styled.div`
display: flex;
justify-content: center;
align-items: center;
gap: 10px;
`;

export const WrapperMail = styled('div')(({ theme }) => `
display: flex;
justify-content: center;
align-items: center;
gap: 4px;
`);

export const WrapperSocial = styled('div')(({ theme }) => `
display: flex;
justify-content:flex-start;
align-items: center;
gap: 4px;
`);

export const LinkEmail = styled(Link)(({ theme }) => `
color: ${theme.palette.text.primary};
font-weight: 700;
`);

export const Follow = styled('span')(({ theme }) => `

`);

export const LinkSocial = styled(Link)(({ theme }) => `
display: flex;
justify-content: center;
align-items: center;
`);


export const IconInsta = styled(InstagramIcon)(({ theme }) => `
width: 24px;
height: 24px;
color: ${theme.palette.text.accent};
`);

export const IconFacebook = styled(FacebookIcon)(({ theme }) => `
width: 24px;
height: 24px;
color: ${theme.palette.primary.main};
`);

export const Copyright = styled('p')(({ theme }) => `

`);