import styled from '@emotion/styled';
import { Link } from 'react-router-dom';
import FacebookIcon from '@mui/icons-material/Facebook';
import InstagramIcon from '@mui/icons-material/Instagram';
import AccountBoxIcon from '@mui/icons-material/AccountBox';
import LogoutIcon from '@mui/icons-material/Logout';

export const Header = styled('header')(({ theme }) => `
display: flex;
justify-content: center;
align-items: center;
height: 50px;
width: 100%;
background-color: ${theme.palette.background.primary};
box-shadow: 0px 2px 4px -1px rgba(0,0,0,0.2);
`);

export const Navigation = styled('nav')(() => `
width:100%;
max-width: 1280px;
display: flex;
justify-content: space-between;
align-items: center;
padding: 10px 20px;
`);

export const WrapperSocial = styled.div`
display: flex;
justify-content: flex-end;
align-items: center;
gap:10px;
`;

export const LinkLogo = styled(Link)(({ theme }) => `
display: flex;
justify-content: flex-start;
align-items: center;
color: ${theme.palette.text.primary};
font-weight: 700;
text-transform: uppercase;
gap:4px;
font-size: 14px;
@media (min-width: 600px) {
   font-size: 16px;
}
`);

export const LinkSocial = styled(Link)(() => `
width: 24px;
height:24px;
`);

export const BtnSocial = styled('div')(() => `
width: 24px;
height:24px;
cursor: pointer;
`);

export const IconAccount = styled(AccountBoxIcon)(({ theme }) => `
width: 24px;
height: 24px;
color: ${theme.palette.text.primary};
/* color: ${theme.palette.primary.main}; */
`);
export const IconLogout = styled(LogoutIcon)(({ theme }) => `
width: 24px;
height: 24px;
color: ${theme.palette.text.primary};
/* color: ${theme.palette.primary.main}; */
`);

export const IconInsta = styled(InstagramIcon)(({ theme }) => `
width: 24px;
height: 24px;
color: ${theme.palette.text.accent};
color: ${theme.palette.text.primary};
`);

export const IconFacebook = styled(FacebookIcon)(({ theme }) => `
width: 24px;
height: 24px;
color: ${theme.palette.primary.main};
color: ${theme.palette.text.primary};
`);

