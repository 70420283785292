import axios from '../utils/axios';
import toast from 'react-hot-toast';
import { useMutation, useQueryClient } from 'react-query';
import { ERROR_MESSAGES } from '../utils/constants';
import { useAuth } from '../context/authContext';
import { useNavigate } from 'react-router-dom';


// Function to register user request
const redigisterNewUser = async userData => {
  try {
    const response = await axios.post('auth/register', userData);
    return response.data;
  } catch (error) {
    throw error;
  }
};

// Custom hook for registering user
export const useRegister = () => {
  const { mutateAsync, isLoading } = useMutation(redigisterNewUser, {
    onSuccess: data => {
    },
    onError: error => {
      if (error?.code === 'ERR_NETWORK' || error?.code === "ERR_BAD_RESPONSE") {
        toast.error(ERROR_MESSAGES['ERR_NETWORK']);
        return;
      }
      if (error?.response && error?.response?.status === 409) {
        toast.error(ERROR_MESSAGES[409]);
        return;
      }
      toast.error(ERROR_MESSAGES[error?.response?.data?.code], { duration: 5000 });
    },
  });
  return {
    redigisterNewUser: mutateAsync,
    isRegistering: isLoading,
  };
};

// Function to resend mail for verify
const resendEmail = async userData => {
  try {
    const response = await axios.post('auth/resendemail', userData);
    return response.data;
  } catch (error) {
    throw error;
  }
};

// Custom hook for resend mail
export const useResendEmail = () => {
  const { mutateAsync, isLoading } = useMutation(resendEmail, {
    onSuccess: () => {
      toast.success('Email di conferma rispedita con successo!');
    },
    onError: error => {
      if (error?.code === 'ERR_NETWORK' || error?.code === "ERR_BAD_RESPONSE") {
        toast.error(ERROR_MESSAGES['ERR_NETWORK']);
        return;
      }
      toast.error(ERROR_MESSAGES[error?.response?.data?.code], { duration: 5000 });
    },
  });
  return {
    resendEmail: mutateAsync,
    isResendingEmail: isLoading,
  };
};

// Function to login user request
const loginUser = async userData => {
  try {
    const response = await axios.post('auth/login', userData);
    return response.data;
  } catch (error) {
    throw error;
  }
};

// Custom hook for logining user
export const useLogin = () => {
  const queryClient = useQueryClient();
  const { mutateAsync, isLoading } = useMutation(loginUser, {
    onSuccess: ({ user }) => {
      toast.success('Accesso effettuato con successo!', { duration: 1000 });
      queryClient.setQueryData('/student', user);
    },
    onError: error => {
      if (error?.code === 'ERR_NETWORK' || error?.code === "ERR_BAD_RESPONSE") {
        toast.error(ERROR_MESSAGES['ERR_NETWORK']);
        return;
      }
      if (error?.response?.status === 403 && error?.response?.data?.message === "Email is not verified") {
        toast(ERROR_MESSAGES["ERR_EMAIL_NOT_VERIFIED"], {
          icon: '⚠️',
          duration: 7000,
        });
        return;
      }
      toast.error(ERROR_MESSAGES[error?.response?.data?.code], { duration: 5000 });
    },
  });
  return {
    loginUser: mutateAsync,
    isLogining: isLoading,
  };
};

// Function for logout
const logoutUser = async () => {
  try {
    await axios.get('auth/logout');
  } catch (error) {
    throw error;
  }
};

// Custom hook for logout
export const useLogout = () => {
  const { logout } = useAuth();
  const navigate = useNavigate();
  const queryClient = useQueryClient();
  const { mutateAsync, isLoading } = useMutation(logoutUser, {
    onSuccess: async () => {
      toast.success('Logout effettuato con successo. A presto!', { duration: 2000 });
      logout();
      queryClient.removeQueries();
      navigate('/');
    },
    onError: error => {
      if (error?.code === 'ERR_NETWORK' || error?.code === "ERR_BAD_RESPONSE") {
        toast.error(ERROR_MESSAGES['ERR_NETWORK']);
        return;
      }
      if (error.response.data.code === 401) {
        logout();
        queryClient.removeQueries();
        navigate('/');
        return;
      }
      toast.error(ERROR_MESSAGES[error?.response?.data?.code], { duration: 5000 });
    },
  });

  return {
    logoutUser: mutateAsync,
    isLogouting: isLoading,
  };
};

// Function for forgot password
const forgotPassword = async userData => {
  try {
    const response = await axios.post('auth/forgotpassword', userData);
    return response.data;
  } catch (error) {
    throw error;
  }
};

// Custom hook for forgot password
export const useForgotPassword = () => {
  const { mutateAsync, isLoading } = useMutation(forgotPassword, {
    onSuccess: () => {
      toast.success(
        "Ti abbiamo inviato un'email con le istruzioni per creare una nuova password.",
        { duration: 5000 }
      );
    },
    onError: error => {
      if (error?.code === 'ERR_NETWORK' || error?.code === "ERR_BAD_RESPONSE") {
        toast.error(ERROR_MESSAGES['ERR_NETWORK']);

        return;
      }
      if (error.response.data.message === 'User not found') {
        toast(ERROR_MESSAGES["ERR_USER_NOT_FOUND"], {
          icon: '⚠️',
          duration: 5000,
        });
        return;
      }
      toast.error(ERROR_MESSAGES[error?.response?.data?.code], { duration: 5000 });
    },
  });
  return {
    forgotPassword: mutateAsync,
    isSendingPasswordReset: isLoading,
  };
};

// Function for reset password
const resetPassword = async ({ resetPasswordToken, password }) => {
  try {
    const response = await axios.post(
      `auth/resetpassword/${resetPasswordToken}`,
      { password }
    );
    return response.data;
  } catch (error) {
    throw error;
  }
};

// Custom hook for reset password
export const useResetPassword = () => {
  const navigate = useNavigate();
  const { mutateAsync, isLoading } = useMutation(resetPassword, {
    onSuccess: () => {
      toast.success('La password è stata reimpostata con successo!', {
        duration: 5000,
      });

    },
    onError: error => {
      if (error?.response?.data?.message === 'User not found or invalid token') {
        toast(ERROR_MESSAGES["ERR_USER_OR_TOKEN"], {
          icon: '⚠️',
          duration: 5000,
        });
        navigate('/login');
        return;
      }
      if (error?.response?.data?.message === 'Reset password token has expired') {
        toast(ERROR_MESSAGES["ERR_TOKEN_EXPIRED"], {
          icon: '⚠️',
          duration: 5000,
        });
        return;
      }
      toast.error(
        'Si è verificato un errore durante la reimpostazione della password. Si prega di riprovare più tardi.',
        { duration: 5000 }
      );
    },
  });

  return {
    resetPassword: mutateAsync,
    isResetingPassword: isLoading,
  };
};