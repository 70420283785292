import Cookies from 'js-cookie';
import { ROLE } from './constants';

Cookies.defaults = {
    expires: 1, // Days of expiration   
    secure: true, // Set to true if your website is served over HTTPS
    sameSite: 'None' // Protects against CSRF attacks by asserting that a cookie must not be sent with cross-origin requests
};

export const setCookie = (name, value) => {
    Cookies.set(name, value);
};

export const getCookie = (name) => {
    return Cookies.get(name);
};

export const removeCookie = (name) => {
    Cookies.remove(name);
};

export const removeUserCookies = () => {
    removeCookie('userName');
    removeCookie('userEmail');
    removeCookie('userLastName');
    removeCookie('userId');
    removeCookie('userRole');
};

export const checkAuthTokenFn = () => {
    const userId = getCookie('userId');
    if (!userId) {
        removeUserCookies();
        window.location.href = '/login';
        return;
    }
};

export const checkRoleStudentFn = () => {
    const role = getCookie('userRole');
    if (role !== ROLE.STUDENT) {
        removeUserCookies();
        window.location.href = '/login';
        return;
    }
};

export const checkRoleTeacherFn = () => {
    const role = getCookie('userRole');
    if (role !== ROLE.TEACHER) {
        removeUserCookies();
        window.location.href = '/login';
        return;
    }
};