import styled from "@emotion/styled";
import { Link } from "react-router-dom";

const Wrapper=styled.div`
display: flex;
flex-direction: column;
justify-content: flex-start;
align-items: flex-start;
gap: 10px;
font-size: 16px;
padding: 20px;
@media (min-width: 400px) {
   align-items: center;
}
`
const WrapperLink=styled.p`
    display: flex;
justify-content: flex-start;
align-items: center;
gap: 10px;
flex-wrap: wrap;
`

const LinkStyled=styled(Link)(({theme})=>`
    color: ${theme.palette.primary.main};
    font-weight: 700;
`)
const PageNotFound = () => {

    return (
        <Wrapper>
        <h1>Ops! La pagina non è stata trovata.</h1>
        <p>La pagina che stai cercando non esiste.</p>
        <WrapperLink>Torna alla <LinkStyled to='/'>Home</LinkStyled> per esplorare la nostra app.</WrapperLink>
        </Wrapper>
    )
};

export default PageNotFound;