export const CATEGORY = {
    ECOLIER: 'ecolier',
    BENJAMIN: 'benjamin',
    CADET: 'cadet',
    JUNIOR: 'junior',
    STUDENT: 'student',
};

export const TYPE = {
    INSTITUTE: 'institute',
    SEMIFINAL: 'semifinal',
    FINAL: 'final',
};

export const LEVELNAME = {
    ELEMENTARY: 'elementary',
    MEDIUM: 'medium',
    HARD: 'hard',
    STANDARD: "standard",
};

export const LEVEL_NAME_UI = {
    [LEVELNAME.ELEMENTARY]: 'base',
    [LEVELNAME.MEDIUM]: 'intermedio',
    [LEVELNAME.HARD]: 'avanzato',
    [LEVELNAME.STANDARD]: "mix",
};

export const LEVELPOINTS = {
    [LEVELNAME.ELEMENTARY]: 3,
    [LEVELNAME.MEDIUM]: 4,
    [LEVELNAME.HARD]: 5
};

export const DURATION = {
    TIMER_75: 75,
    TIMER_50: 50,
    TIMER_30: 30,
};

export const TIMER = {
    QUANTITY_24: 75,
    QUANTITY_15: 50,
    QUANTITY_9: 30,
    QUANTITY_30: 75,
    QUANTITY_21: 50,
    QUANTITY_12: 30,
};

export const QUANTITY = {
    QUANTITY_24: 24,
    QUANTITY_15: 15,
    QUANTITY_9: 9,
};

export const QUANTITY_ECOLIER = {
    QUANTITY_24: 24,
    QUANTITY_15: 15,
    QUANTITY_9: 9,
};

export const QUANTITY_OTHER = {
    QUANTITY_30: 30,
    QUANTITY_21: 21,
    QUANTITY_12: 12,
};

export const CATEGORY_DESCRIPTION = {
    [CATEGORY.ECOLIER]: '4° e 5° scuola primaria',
    [CATEGORY.BENJAMIN]: '1° e 2° scuola secondaria di primo grado',
    [CATEGORY.CADET]: '3° scuola secondaria di primo grado',
    [CATEGORY.JUNIOR]: '1° e 2° scuola secondaria di secondo grado',
    [CATEGORY.STUDENT]: '3°, 4° e 5° scuola secondaria di secondo grado',
};

export const CATEGORY_LIST = Object.values(CATEGORY).map(categoryName => {
    return {
        category: categoryName,
        description: CATEGORY_DESCRIPTION[categoryName],
        inProgress: (categoryName === CATEGORY.STUDENT || categoryName === CATEGORY.JUNIOR),
    };
});

export const QUANTITY_DESCRIPTION = {
    [QUANTITY.QUANTITY_24]: 'si svolge in 75 minuti',
    [QUANTITY.QUANTITY_15]: 'si svolge in 50 minuti',
    [QUANTITY.QUANTITY_9]: 'si svolge in 30 minuti',
};

export const QUANTITY_DESCRIPTION_ECOLIER = {
    [QUANTITY_ECOLIER.QUANTITY_24]: 'si svolge in 75 minuti',
    [QUANTITY_ECOLIER.QUANTITY_15]: 'si svolge in 50 minuti',
    [QUANTITY_ECOLIER.QUANTITY_9]: 'si svolge in 30 minuti',
};

export const QUANTITY_DESCRIPTION_OTHER = {
    [QUANTITY_OTHER.QUANTITY_30]: 'si svolge in 75 minuti',
    [QUANTITY_OTHER.QUANTITY_21]: 'si svolge in 50 minuti',
    [QUANTITY_OTHER.QUANTITY_12]: 'si svolge in 30 minuti',
};

export const QUANTITY_LIST_ECOLIER = Object.values(QUANTITY_ECOLIER).map(quantityQuestion => ({
    quantity: quantityQuestion,
    description: QUANTITY_DESCRIPTION_ECOLIER[quantityQuestion],
}));

export const QUANTITY_LIST_OTHER = Object.values(QUANTITY_OTHER).map(quantityQuestion => ({
    quantity: quantityQuestion,
    description: QUANTITY_DESCRIPTION_OTHER[quantityQuestion],
}));


export const QUANTITY_LIST = Object.values(QUANTITY).map(quantityQuestion => ({
    quantity: quantityQuestion,
    description: QUANTITY_DESCRIPTION[quantityQuestion],
}));

export const LEVEL_DESCRIPTION = {
    [LEVELNAME.STANDARD]: 'contiene 3 livelli di difficoltà',
    [LEVELNAME.ELEMENTARY]: 'contiene soloi i quesiti di livello ELEMENTARY',
    [LEVELNAME.MEDIUM]: 'contiene soloi i quesiti di livello MEDIUM',
    [LEVELNAME.HARD]: 'contiene soloi i quesiti di livello HARD',
};

export const LEVEL_LIST = Object.values(LEVELNAME).map(levelName => ({
    level: levelName,
    description: LEVEL_DESCRIPTION[levelName],
}));

export const ROLE = {
    'TEACHER': 'teacher',
    'STUDENT': 'student',
};

export const QUIZ_TYPE = {
    'PERSONAL': 'personal',
    "CLASSROOM": 'classroom',
};

export const MAX_LIMIT = {
    'QUIZ': 15,
    'CLASSROOM': 3,
    'STUDENT_IN_CLASSROOM': 35,
    'QUIZ_IN_CLASSROOM': 10,
};



export const ERROR_MESSAGES = {
    400: "Siamo spiacenti, server non trovato. Torna alla pagina Home e riprova.",
    401: "Email o password non validi. Si prega di verificare e riprovare.",
    // 401: "Siamo spiacenti, non hai i permessi per accedere a questa pagina. Effettua il login e riprova.",
    403: "Siamo spiacenti, non hai i permessi per accedere a questa pagina.",
    404: "Siamo spiacenti, qulacosa è andato storto. Torna indietro e riprova.",
    409: "Siamo spiacenti, questa email esiste già.",
    500: "Siamo spiacenti, il server non risponde. Riprova più tardi.",
    'ERR_NETWORK': "Siamo spiacenti, il server non risponde. Riprova più tardi.",
    'ERR_ID': "Siamo spiacenti, il quiz che stai cercando non esiste o è stato cancellato. Crea uno nuovo quiz.",
    'ERR_ID_CLASSROOM': "Siamo spiacenti, la classroom che stai cercando non esiste.",
    'ERR_EXIST_CLASSROOM': "La classroom con questo nome esiste già. Sciegli un altro nome.",
    "ERR_EXIST_STUDENT": "Questo studente è già iscritto alla classroom.",
    "ERR_QUIZ_ALREADY_RESOLVED": "Hai già risolto questo quiz. Non puoi risolverlo più di una volta.",
    "ERR_EXIST_QUIZ": "Questo quiz è già presente nella classroom.",
    'ERR_NOT_FOUND_CLASSROOM': "La classroom non è stata trovata. Controlla il codice di accesso e riprova.",
    'ERR_NOT_FOUND_CLASSROOM_FOR_ADD_QUIZ': "La classroom non è stata trovata. Torna indietro e riprova.",
    'ERR_NOT_FOUND_CLASSROOM_OR_STUDENT': "La classroom o lo studente non sono stati trovati. Si prega di ricaricare la pagina e riprovare.",
    'ERR_NOT_FOUND_CLASSROOM_OR_QUIZ': "La classroom o il quiz non sono stati trovati. Si prega di ricaricare la pagina e riprovare.",
    'ERR_NOT_FOUND_QUIZ': "Il quiz non è trovato o stato già cancellato.",
    'ERR_USER_NOT_EROLLED_IN_CLASSROOM': "Non sei iscritto a questa classroom. Inserisci il codice di accesso per entrare.",
    'ERR_USER_NOT_FOUND': "L'utente con questo indirizzo email non esiste. Per favore, assicurati di aver inserito l'email corretta.",
    'ERR_USER_OR_TOKEN': "Il token di reset della password non è valido o è stato già utilizzato. Richiedi un nuovo link",
    'ERR_TOKEN_EXPIRED': "Il token di reset della password è scaduto. Richiedi un nuovo link.",
    'ERR_EMAIL_NOT_VERIFIED': "La tua email non è stata ancora verificata. Ti abbiamo inviato un'email di verifica. Per favore, controlla la tua casella di posta e segui le istruzioni per verificare il tuo account. Grazie!",
    'ERR_CLASSROOM_FULL': "La classroom ha raggiunto il numero massimo di studenti. Non è possibile aggiungere ulteriori studenti.",
    'ERR_ACCESS_CODE_NOT_VALID': 'Codice di accesso non valido',
    'ERR_REACHED_MAX_LIMIT_OF_CLASSROOM': 'Hai raggiunto il limite massimo di classroom. Se hai bisogno di ulteriori classroom, considera di eliminarne alcune esistenti.',
    'ERR_REACHED_MAX_LIMIT_QUIZ_CLASSROOM': 'Hai raggiunto il limite di 10 quiz in questa classroom! Rimuovi un quiz per aggiungerne un altro.',
    'ERR_REACHED_MAX_LIMIT_QUIZ': 'Hai raggiunto il limite di 15 quiz! Rimuovi un quiz dalla lista di quiz creati da te per aggiungerne un altro.',
};

export const ARR_ID_ECOLIER = [{ "_id": "65098156f93ea9bef910b2d1", "year": 2001 }, { "_id": "6509820ff93ea9bef910b2d2", "year": 2002 }, { "_id": "650982a2f93ea9bef910b2d3", "year": 2003 }, { "_id": "650982fdf93ea9bef910b2d4", "year": 2004 }, { "_id": "65098337f93ea9bef910b2d5", "year": 2005 }, { "_id": "65213a3543f5a0b470cfeabc", "year": 2006 }, { "_id": "652436f843f5a0b470cfeabd", "year": 2007 }, { "_id": "6524370843f5a0b470cfeabe", "year": 2008 }, { "_id": "6524371643f5a0b470cfeabf", "year": 2009 }, { "_id": "6524372243f5a0b470cfeac0", "year": 2010 }, { "_id": "65254b3843f5a0b470cfeac1", "year": 2011 }, { "_id": "65254b4143f5a0b470cfeac2", "year": 2012 }, { "_id": "65254b4943f5a0b470cfeac3", "year": 2013 }, { "_id": "65254b5443f5a0b470cfeac4", "year": 2014 }, { "_id": "65269cd743f5a0b470cfeac5", "year": 2015 }, { "_id": "65269d1043f5a0b470cfeac6", "year": 2016 }, { "_id": "65269d1d43f5a0b470cfeac7", "year": 2017 }, { "_id": "65269d3343f5a0b470cfeac8", "year": 2018 }, { "_id": "6529626e0ba76fdb0cf62077", "year": 2019 }, { "_id": "652962770ba76fdb0cf62078", "year": 2020 }, { "_id": "652962800ba76fdb0cf62079", "year": 2021 }, { "_id": "652962860ba76fdb0cf6207a", "year": 2022 }];

export const ARR_ID_BENJAMIN = [{ "_id": "6560c818c696a3c8e0fd3c42", "year": 2000 }, { "_id": "6565ed15c696a3c8e0fd3c4e", "year": 2001 }, { "_id": "65674561c696a3c8e0fd3c52", "year": 2002 }, { "_id": "65676a57c696a3c8e0fd3c54", "year": 2003 }, { "_id": "656dbb01dc3f879245dd04c0", "year": 2004 }, { "_id": "658a981a0405113dd2a35887", "year": 2005 }, { "_id": "658bdb2444d19c1f506c5259", "year": 2006 }, { "_id": "658c465844d19c1f506c525f", "year": 2007 }, { "_id": "658d4b1cb1a405f4d120a860", "year": 2008 }, { "_id": "658d7ef3b1a405f4d120a863", "year": 2009 }, { "_id": "658d8c7eb1a405f4d120a866", "year": 2010 }, { "_id": "65968dc5bdb4e5bba8d6ca50", "year": 2011 }, { "_id": "6596c534bdb4e5bba8d6ca54", "year": 2012 }, { "_id": "6596dc1abdb4e5bba8d6ca57", "year": 2013 }, { "_id": "659931abbdb4e5bba8d6ca5d", "year": 2014 }, { "_id": "65996499bdb4e5bba8d6ca62", "year": 2015 }, { "_id": "6599833cbdb4e5bba8d6ca68", "year": 2016 }, { "_id": "659e7516bdb4e5bba8d6ca6d", "year": 2017 }, { "_id": "659ea00fbdb4e5bba8d6ca74", "year": 2018 }, { "_id": "659fd0a2bdb4e5bba8d6ca79", "year": 2019 }, { "_id": "65a156d9bdb4e5bba8d6ca7e", "year": 2020 }, { "_id": "65a188a898910dee7f87ca2c", "year": 2021 }];

export const ARR_ID_CADET = [{ "_id": "656dff17dc3f879245dd04c3", "year": 2001 }, { "_id": "656f2c11dc3f879245dd04c9", "year": 2002 }, { "_id": "65705ed5dc3f879245dd04cc", "year": 2003 }, { "_id": "657092c6dc3f879245dd04d0", "year": 2004 }, { "_id": "6571b101dc3f879245dd04d5", "year": 2005 }];

export const ARR_ID_JUNIOR = [{ "_id": "65a575f32c35a27b3988cc36", "year": 2001 }, { "_id": "65dc522ac4c142e92e25f900", "year": 2002 }, { "_id": "65dc910ac4c142e92e25f906", "year": 2003 }, { "_id": "6740b33e3afad7713969c354", "year": 2004 }, { "_id": "6745b4023c187f4c6ce65b4a", "year": 2005 }];

export const ARR_ID_STUDENT = [{ "_id": "65a66c67ea75944135807dac", "year": 2001 }, { "_id": "65ddb936c4c142e92e25f90a", "year": 2002 }, { "_id": "65ddf296c4c142e92e25f90d", "year": 2003 }];

export const ARR_CATEGORY_OBJ = [
    { category: CATEGORY.ECOLIER, arrId: ARR_ID_ECOLIER },
    { category: CATEGORY.BENJAMIN, arrId: ARR_ID_BENJAMIN },
    { category: CATEGORY.CADET, arrId: ARR_ID_CADET },
    { category: CATEGORY.JUNIOR, arrId: ARR_ID_JUNIOR },
    { category: CATEGORY.STUDENT, arrId: ARR_ID_STUDENT },
];


export const META_DATA = {
    home: {
        title: "Home | Mathlab",
        keywords: "gare di matematica, Kangourou, math, mathlab, quiz, quiz di matematica, allenamento per le gare di matematica, preparazione alle gare di matematica, matematica, didattica della matematica, istruzione matematica, competenze matematiche, abilità matematiche, problem solving matematico, matematica applicata, matematica creativa, apprendimento divertente",
        description: "Preparati per le gare matematiche con quiz interattivi su mathlab.it. Allenati per i giochi matematici Kangourou e migliora le tue abilità matematiche in modo divertente",
    },
    simulation: {
        title: "Simulazione | Mathlab",
        keywords: "simulazione, categorie, ecolier, cadet, benjamin, junior, student, math, mathlab, gare di matematica, Kangourou, quiz di matematica, allenamento per le gare di matematica, preparazione alle gare di matematica, matematica, didattica della matematica, istruzione matematica, competenze matematiche, abilità matematiche, problem solving matematico, matematica applicata, matematica creativa",
        description: "Allenati con le edizioni precedenti di Kangourou. Seleziona la tua categoria e l'anno della gara dei quiz ufficiali Kangourou",
    },
    quiz: {
        title: "Quiz | Mathlab",
        keywords: "quiz, math, mathlab, quiz, giochi matematici, Kangourou, domande, risposte, sfide, preparazione, miglioramento delle abilità, gare di matematica, quiz di matematica, allenamento per le gare di matematica, preparazione alle gare di matematica, matematica, didattica della matematica, istruzione matematica, competenze matematiche, abilità matematiche, problem solving matematico, matematica applicata, matematica creativa",
        description: "Fai una simulazione dei giochi matematici Kangourou Italia",
    },
    customQuiz: {
        title: "Quiz | Mathlab",
        keywords: "quiz, math, mathlab, quiz, giochi matematici, Kangourou, domande, risposte, sfide, preparazione, miglioramento delle abilità, gare di matematica, quiz di matematica, allenamento per le gare di matematica, preparazione alle gare di matematica, matematica, didattica della matematica, istruzione matematica, competenze matematiche, abilità matematiche, problem solving matematico, matematica applicata, matematica creativa",
        description: "Risolvi il quiz personalizzato e preparati per i giochi matematici",
    },
    creator: {
        title: "Crea quiz | Mathlab",
        keywords: "crea quiz, personalizzazione, giochi matematici, Kangourou, sfide personalizzate, preparazione, condivisione, abilità matematiche,quantità di domande, livello di difficoltà",
        description: "Crea il tuo quiz di matematica personalizzato. Condividilo con gli amici",
    },
    about: {
        title: "About | Mathlab",
        keywords: "gare di matematica, Kangourou, math, mathlab, quiz, quiz di matematica, allenamento per le gare di matematica, preparazione alle gare di matematica, matematica, didattica della matematica, istruzione matematica, competenze matematiche, abilità matematiche, problem solving matematico, matematica applicata, matematica creativa, apprendimento divertente",
        description: "Scopri la nostra mission e i nostri valori, volti a rendere la matematica accessibile e coinvolgente per tutti.",
    },
    dashboard: {
        title: "Dashboard | Mathlab",
        keywords: "dashboard, mathlab, gestione quiz, classroom, insegnanti, alunni, quiz personalizzati, risultati, competenze matematiche, preparazione gare matematiche, organizzazione quiz, monitoraggio progresso, statistica, Kangourou",
        descriptionTeacher: "Accedi alla tua dashboard su Mathlab.it per gestire i quiz, creare classroom, monitorare i progressi degli studenti e prepararli alle gare matematiche con strumenti personalizzati.",
        descriptionStudent: "Accedi alla tua dashboard su Mathlab.it per gestire i quiz, entrare nelle classroom, monitorare i tuoi progressi e prepararti alle gare matematiche con strumenti personalizzati.",
    },
    classroom: {
        title: "Classroom | Mathlab",
        keywords: "classroom, mathlab, gestione classroom, insegnanti, alunni, quiz classroom, accesso classroom, codice classroom, quiz condivisi, preparazione gare matematiche, progressi studenti, competenze matematiche, Kangourou",
        descriptionTeacher: "Gestisci le tue classroom su Mathlab.it. Crea quiz, invita gli studenti con un codice e monitora i loro progressi mentre si preparano per le gare matematiche.",
        descriptionStudent: "Accedi alle classroom su Mathlab.it inserendo il codice fornito dal tuo insegnante. Risolvi quiz, monitora i tuoi progressi e preparati per le gare matematiche.",
    },
    statistic: {
        title: "Risultati Quiz | Mathlab",
        keywords: "risultati quiz, classroom, mathlab, insegnanti, alunni, progressi quiz, analisi quiz, risoluzione quiz, prestazioni studenti, monitoraggio risultati, competenze matematiche, gare matematiche, Kangourou",
        descriptionTeacher: "Consulta i risultati dei quiz dei tuoi studenti all'interno della classroom su Mathlab.it. Confronta i punteggi tra gli alunni, monitora i progressi e supportali nel migliorare le loro abilità matematiche.",
        descriptionStudent: "Monitora il tuo punteggio e confrontalo con quello dei tuoi compagni all'interno della stessa classroom su Mathlab.it. Analizza i risultati per capire dove puoi migliorare e prepararti al meglio per le gare matematiche.",
    },
    answers: {
        title: "Risposte | Mathlab",
        keywords: "risposte, quiz completati, mathlab, domande, corrette, sbagliate, analisi quiz, competenze matematiche, gare matematiche, Kangourou",
        description: "Rivedi il quiz che hai completato su Mathlab.it. Visualizza le domande a cui hai risposto, controlla se le tue risposte erano corrette o sbagliate e scopri le risposte giuste.",
    },
    overview: {
        title: "Dettagli Quiz | Mathlab",
        keywords: "panoramica quiz, dettagli quiz, classroom, mathlab, insegnanti, domande, risposte, quiz creati, analisi quiz, gestione quiz, competenze matematiche, gare matematiche",
        description: "Accedi alla panoramica dei quiz che hai creato per la tua classroom su Mathlab.it. Visualizza le domande e le risposte senza la possibilità di risolvere il quiz, per un'analisi dettagliata e una preparazione efficace per i tuoi studenti.",
    },
    privacyPolicy: {
        title: "Privacy | Mathlab",
        keywords: "informativa sulla privacy, protezione dati, mathlab, dati personali, cookie, diritti degli utenti, raccolta dati, utilizzo dati, accesso ai dati, sicurezza, consensi",
        description: "Scopri come Mathlab.it raccoglie, utilizza e protegge i tuoi dati personali. Leggi la nostra informativa sulla privacy per comprendere i tuoi diritti e come garantiamo la sicurezza delle informazioni.",
    },
    login: {
        title: "Login | Mathlab",
        keywords: "login, accesso, mathlab, autenticazione, account, insegnanti, alunni, quiz, registrazione, giochi matematici, creare classroom",
        description: "Accedi al tuo account su Mathlab.it per esplorare quiz di matematica, prepararti ai giochi matematici e creare classroom. Se non hai un account, registrati ora.",
    },
    registration: {
        title: "Registrazione | Mathlab",
        keywords: "registrazione, crea account, mathlab, insegnanti, alunni, autenticazione, giochi matematici, classroom, quiz, preparazione",
        description: "Registrati su Mathlab.it per accedere a quiz di matematica, creare classroom e prepararti ai giochi matematici. Inizia il tuo percorso di apprendimento oggi stesso!",
    },
    resetPassword: {
        title: "Reset Password | Mathlab",
        keywords: "reset password, recupero password, mathlab, autenticazione, sicurezza, accesso account, insegnanti, alunni",
        description: "Hai dimenticato la password? Ripristina la tua password su Mathlab.it per accedere di nuovo al tuo account. Segui i semplici passaggi per garantire la sicurezza del tuo accesso.",
    }
}

