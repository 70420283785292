import React, { lazy, Suspense } from "react";
import ReactDOM from "react-dom/client";
import { createTheme, ThemeProvider } from "@mui/material/styles";
import { CssBaseline } from "@mui/material";
import GlobalStyles from "@mui/material/GlobalStyles";
import {
  createBrowserRouter,
  createRoutesFromElements,
  Route,
  RouterProvider,
} from "react-router-dom";
import { Layout } from "./Layout";
import PageNotFound from "./views/PageNotFound";
import { ProviderQuery } from "./components/ProviderQuery";
import { Spinner } from "./components/Spinner";
import { HelmetProvider } from "react-helmet-async";
import RedirectCategory from "./components/RedirectCategory";
import { AuthProvider } from "./context/authContext";
import { ProtectedRoute } from "./components/ProtectedRoute";


//  Lazy loading components
const AboutLazy = lazy(() => import("./views/About"));
const HomeLazy = lazy(() => import("./views/Home"));
const GeneralConditionsLazy = lazy(() => import('./views/GeneralConditions'));
const CategoryCreateQuizLazy=lazy(()=>import("./views/CategoryCreateQuiz"))
const SimulationLazy = lazy(() => import("./views/Simulation"));
const QuizLazy = lazy(() => import("./views/Quiz"));
const CustomQuizLazy = lazy(() => import("./views/CustomQuiz"));
const DashboardLazy = lazy(() => import("./views/Dashboard"));
const ClassroomLazy = lazy(() => import('./views/Classroom'));
const StatisticLazy = lazy(() => import('./views/Statistic'));
const AnswersLazy = lazy(() => import('./views/Answers'));
const QuizOverviewLazy = lazy(() => import('./views/QuizOverview'));

// Lazy import for registration and login 
const RegisterLazy = lazy(() => import("./views/Register"));
const LoginLazy = lazy(() => import("./views/Login"));
const ResetPasswordLazy = lazy(() => import("./views/ResetPassword"));

const router = createBrowserRouter(
  createRoutesFromElements(
    <>
      <Route path="/" element={<Suspense fallback={<Spinner/>}><Layout /></Suspense>}>        
        <Route index element={<HomeLazy />} /> 
        <Route path='general-conditions' element={<GeneralConditionsLazy />} /> 
        <Route path="/category/:id" element={<RedirectCategory/>} />
        <Route path=":category" element={<CategoryCreateQuizLazy />} />
        <Route path="simulation" element={<SimulationLazy />} />
        <Route path="simulation/:id" element={<QuizLazy />} />
        <Route path="custom/:id" element={<CustomQuizLazy />} />
        <Route path="about" element={<AboutLazy />} />

        <Route path="register" element={<RegisterLazy />} />
        <Route path="login" element={<LoginLazy />} />
        <Route path="resetpassword/:resetPasswordToken" element={<ResetPasswordLazy />} />
        
        <Route path="dashboard" element={<ProtectedRoute element={<DashboardLazy />} />} />  
        <Route path="classroom/:id" element={<ProtectedRoute element={<ClassroomLazy />} />} />         
        <Route path="classroom/:idClassroom/statistic/:idQuiz" element={<ProtectedRoute element={<StatisticLazy />} />} />         
        <Route path="answers/:idResolvedQuiz" element={<ProtectedRoute element={<AnswersLazy />} />} />  
        <Route path="overview/:idQuiz" element={<ProtectedRoute element={<QuizOverviewLazy />} />} />
        
      </Route>
      <Route path="*" element={<PageNotFound />} />
    </>
  )
);

const theme = createTheme({
  palette: {
    text: {
      primary: "#0b4a72",
      light: "#FFFFFF",
      accent: "#EF6264",
      disabled: "rgba(0, 0, 0, 0.26)",
    },
    common: {
      black: "#0b4a72",
      white: "#FFFFFF",
    },
    background: {
      primary: '#d2e9ff',
      dark: "#0b4a72",
      accent: "#FCFFA6",
      light: "#FFFFFF"
    },
    primary: {
      main: "#2192FF",
      dark: "#4da7ff",
      contrastText: "#FFFFFF",
      error: "#f7b0b1",
      success: "#8de2aa",
    },
    secondary: {
      main: '#d2e9ff',
      dark: '#a6d3ff',
      contrastText: "#0b4a72",
    },
    tertiary: {
      main: '#FCFFA6',
      dark: '#faff73',
      contrastText: "#0b4a72",
    },
    quaternary: {
      main: '#1cc555',
      dark: '#32ca66',
      contrastText: "#FFFFFF",
    },
    quinario: {
      main: "#EF6264",
      dark: "#F07173 ",
      contrastText: "#FFFFFF",
    },
    ecolier: {
      main: "#ffc107",
      dark: "#ffcd38 ",
      contrastText: "#FFFFFF",
    },
     benjamin: {
      main: "#28a745",
      dark: "#52b86a ",
      contrastText: "#FFFFFF",
    },
      cadet: {
      main: "#dc3545",
      dark: "#e35d6a",
      contrastText: "#FFFFFF",
    },
      junior: {
      main: "#6f42c1",
      dark: "#8b67cd",
      contrastText: "#FFFFFF",
    },
      student: {
      main: "#c18d42",
      dark: "#cda367",
      contrastText: "#FFFFFF",
    },
    base: {
      main: "#0b4a72",
      dark: "#3b6e8e",
      contrastText: "#FFFFFF",
      }
  },
  typography: {
    fontFamily: [
      "Inter",
      "system-ui",
      "Avenir",
      "Helvetica",
      "Arial",
      "sans-serif",
    ].join(","),
    lineHeight: 1.5,
  },
});

const globalStyles = {
  body: {
    minWidth: 280,
    minHeight: "100vh",
  },
  "h1, h2, h3, h4, h5, h6, p, ul, fieldset": {
    margin: 0,
    padding: 0,
  },
  h1: {
    fontSize: 20,
    "@media (min-width:600px)": {
      fontSize: 24,
    },
  },
  h2: {
    fontSize: 18,
    "@media (min-width:600px)": {
      fontSize: 20,
    },
  },
  ul: {
    listStyle: "none",
  },
  a: {
    textDecoration: "none",
    display: "block",
  },
  img: {
    display: "block",
    maxWidth: "100%",
    height: "auto",
  },
  fieldset: {
    width: "100%",
  },
  legend: {
    display: "contents",
  },
};

ReactDOM.createRoot(document.getElementById("root")).render(
  <React.StrictMode>
    <AuthProvider>
        <ProviderQuery>
          <ThemeProvider theme={theme}>
            <HelmetProvider>
              <RouterProvider router={router} />
            </HelmetProvider>
            <CssBaseline />
            <GlobalStyles styles={globalStyles} />
          </ThemeProvider>
        </ProviderQuery>
    </AuthProvider>
  </React.StrictMode>
);