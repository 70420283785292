import { Link, useNavigate } from "react-router-dom";
import { LinkLogo, Header, Navigation, IconInsta, IconFacebook, WrapperSocial, LinkSocial, IconAccount, IconLogout, BtnSocial } from "./AppBar.styled";
import MathlabLogo from '../assets/images/light.svg';
import { useAuth } from "../context/authContext";
import { useLogout } from "../hooks/useAuth";
import { Tooltip } from "@mui/material";

export const AppBar = () => {
    const { isAuthenticated } = useAuth();
    const { logoutUser, isLogouting } = useLogout();
    // const navigate = useNavigate();

    // const logoutRedirectHome = () => {
    //     logoutUser();
    //     navigate('/');
    // }

    return (
        <Header>
            <Navigation>

                <LinkLogo component={Link} to="/" >
                    <img src={MathlabLogo} width="24" height="24" alt="Logo Mathlab" />
                    <span>Home</span>
                </LinkLogo>
                
                <WrapperSocial>

                    {!isAuthenticated &&
                        <Tooltip title="Accedi" placement="bottom">
                            <LinkSocial to="/login" aria-label="login">
                                <IconAccount />
                            </LinkSocial>
                        </Tooltip>}
                    
                    {isAuthenticated &&
                        <>
                        <Tooltip title="Dashboard" placement="bottom">
                            <LinkSocial to="/dashboard?tab=classroom" aria-label="login">
                                <IconAccount />
                            </LinkSocial>
                        </Tooltip>

                        <Tooltip title="Esci" placement="bottom">
                            <BtnSocial onClick={logoutUser} aria-label="logout" disabled={isLogouting}>
                                <IconLogout />
                            </BtnSocial>
                        </Tooltip>
                        </>
                        }

                    {/* <LinkSocial to="https://www.instagram.com/mathlab.it/" target="_blank" rel="noopener noreferrer" aria-label="instagram">                        
                        <IconInsta />
                    </LinkSocial>
                    
                    <LinkSocial to="https://www.facebook.com/profile.php?id=61554178910841" target="_blank" rel="noopener noreferrer" aria-label="facebook">
                        <IconFacebook />
                    </LinkSocial> */}
                
                </WrapperSocial>

            </Navigation>
        </Header>
    );
};