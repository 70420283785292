import styled from '@emotion/styled';
import { Outlet } from 'react-router-dom';
import { AppBar } from './components/AppBar';
import { Toaster } from 'react-hot-toast';
import { Footer } from './components/Footer';

const Wrapper = styled('div')(()=>`  
`);

const WrapperOutlet = styled('div')(() => `
max-width: 1280px;
  margin: 0 auto;
  padding: 20px 20px 40px 20px;
  text-align: center;
  min-height: calc(100vh - 130px);
`);

export const Layout = () => {
    return (
      <Wrapper>
        <AppBar />
        <WrapperOutlet>
          <Outlet />
        </WrapperOutlet>
        <Toaster position="top-right" />
        <Footer/>
        </Wrapper>
    );
};