import { useContext, createContext, useState, useEffect, useMemo } from "react";
import { setCookie, getCookie, removeUserCookies } from "../utils/cookieHelper";

const AuthContext = createContext();

export const useAuth = () => {
    return useContext(AuthContext);
};

export const AuthProvider = ({ children }) => {
    const [isAuthenticated, setIsAuthenticated] = useState(false);
    const [name, setName] = useState(null);
    const [lastName, setLastName] = useState(null);
    const [email, setEmail] = useState(null);
    const [role, setRole] = useState(null);
    const [userId, setUserId] = useState(null);

    const handleLogout = () => {
        removeUserCookies();

        setIsAuthenticated(false);        
        setRole(null);
        setUserId(null);
        setName(null);
        setLastName(null);
        setEmail(null);
    }

    const login = (user) => {
        setCookie('userName', user.name);         
        setCookie('userLastName', user.lastName);
        setCookie('userEmail', user.email); 
        setCookie('userRole', user.role);
        setCookie('userId', user.id);

        setIsAuthenticated(true);        
        setName(user.name); 
        setLastName(user.lastName);  
        setEmail(user.email);
        setRole(user.role);
        setUserId(user.id);
        
        window.localStorage.setItem('authTokenChange', Date.now());
    };

    const logout = () => {
        handleLogout();

        window.localStorage.setItem('authTokenChange', Date.now());
    };
    

    const checkAuthentication = () => {        
        const userId = getCookie('userId');      
        

        if (!userId) {
            handleLogout();
            return;
        }

        const name = getCookie('userName');
        const role = getCookie('userRole');
        const lastName = getCookie('userLastName');
        const email = getCookie('userEmail');

        setIsAuthenticated(true);
        setName(name);
        setRole(role);
        setUserId(userId); 
        setLastName(lastName);
        setEmail(email);
    };

    useEffect(() => {
        checkAuthentication();
        const handleStorageChange = e => {
            if (e.key === 'authTokenChange') {
                checkAuthentication();
                window.localStorage.removeItem('authTokenChange');
            }
        }
        window.addEventListener('storage', handleStorageChange);
        return () => {
            window.removeEventListener('storage', handleStorageChange);
        }
    }, []);

    const value = useMemo(() => ({
        isAuthenticated, role, userId, name, lastName, email, login, logout, checkAuthentication
    }), [isAuthenticated, role, userId, name]);


    return (
        <AuthContext.Provider value={value}>
            {children}
        </AuthContext.Provider>
    );
};