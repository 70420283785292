import styled from "@emotion/styled";
import { CircularProgress } from "@mui/material";

const WrapperSpinner = styled.div`
display: flex;
justify-content: center;
align-items: center;
width: 100%;
padding: 20px;
`

export const Spinner = () => (
    <WrapperSpinner>
             <CircularProgress color='secondary' size={70} />
    </WrapperSpinner>
)